<template>
  <div class="wrap">
    <Navbar :title="$t(`user.${type}`)" />
    <div class="content">
      <!-- <div class="tab">
        <div class="tab_list" :class="tabIndex == index ? 'active' : ''" v-for="item, index in tabList" :key="index"
          @click="tabClick(item, index)">
          {{ $t(item.title) }}
        </div>
      </div> -->
      <div class="list">
        <List :url="type == 'depositRecord' ? '/pay/record' : '/users/extract/record'" ref="listRef"
          wrapHeight="calc(90rem / 16)" :initParams="initParams">
          <template #item="{ item }">
            <div class="list_cont" v-if="type == 'depositRecord'">
              <div class="list_left">
                <img src="@/assets/new/user/tag.svg" alt="">
                <div class="list_conts">
                  <div class="list_type">{{ item.type }}
                    <div class="list_status" :style="{'color':getStatus(item.status).color}">[{{ getStatus(item.status).text }}]</div>
                  </div>
                  <div class="list_time">{{ dateEvent(item.indate) }}</div>
                </div>
              </div>
              <div class="list_right">
                <div class="list_order">{{ $t("newAdd.OrderNum") }}&nbsp;{{ item.payid }}</div>
                <div class="list_money">{{ item.money }}</div>
              </div>
            </div>
            <div class="list_cont" v-else>
              <div class="list_left">
                <img src="@/assets/new/user/tag.svg" alt="">
                <div class="list_conts">
                  <div class="list_type">{{ item.bankinfo?.name }}
                    <div class="list_status">[{{ getStatus2(item.status) }}]</div>
                  </div>
                  <div class="list_bank">{{ item.bankinfo?.bank }} {{ $t('bank.tailNumber') }} {{ item.bankinfo?.card_l4
                    }}</div>
                  <div class="list_time">{{ dateEvent(item.indate) }}</div>
                </div>
              </div>
              <div class="list_right">
                <div class="list_money">{{ item.money }}</div>
                <div class="list_reason" v-if="item.status === '未通过' && item.reason">
                  {{ $t('bank.reason') }}: {{ item.reason }}
                </div>
              </div>
            </div>
          </template>
        </List>
      </div>
    </div>
    <!-- <div class="btns">
      <div class="btn" :class="type == item.name ? 'active' : ''" @click="btnClick(item.name)"
        v-for="item, index in btns" :key="index">
        <img class="img" :src="type == item.name ? item.img_active : item.img" alt="">
        {{ $t(item.label) }}
      </div>
    </div> -->
  </div>
</template>
<script>
import Navbar from '@/components/JNav'
import List from "@/components/List"
import { formatDate } from "@/utils/tools";
export default {
  components: {
    Navbar,
    List
  },
  data() {
    return {
      tabList: [{
        title: 'game.all',
        value: ''
      }, {
        title: 'betting.today',
        value: 'today'
      }, {
        title: 'betting.yesterday',
        value: 'yesterday'
      }, {
        title: 'betting.oneWeek',
        value: 'week'
      }],
      tabIndex: 0,
      type: this.$route.params.type || 'depositRecord',
      btns: [{
        name: 'depositRecord',
        label: 'user.depositRecord',
        img: require('../../assets/new/user/deposit_n_selected.svg'),
        img_active: require('../../assets/new/user/deposit_selected.svg')
      }, {
        name: 'withdrawRecord',
        label: 'user.withdrawRecord',
        img: require('../../assets/new/user/withdraw_n_selected.svg'),
        img_active: require('../../assets/new/user/withdraw_selected.svg')
      }],
      initParams: {
        time: ''
      },
    }
  },
  computed: {

  },
  created() {

  },
  methods: {
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
    service() {
      this.$store.dispatch("toService");
    },
    btnClick(type) {
      this.type = type;
      this.tabIndex = 0;
      this.initParams.time = this.tabList[0].value
      this.$nextTick(() => {
        this.$refs.listRef && this.$refs.listRef.handleRefresh()
      })
    },
    tabClick(item, index) {
      this.tabIndex = index
      if (this.initParams.time != item.value) {
        this.initParams.time = item.value
        this.$nextTick(() => {
          this.$refs.listRef && this.$refs.listRef.handleRefresh()
        })
      }
    },
    getStatus(status) {
      let text = '';
      let color=''
      switch (status) {
        case '已完成':
          text = this.$t("newAdd.succeed");
          color = '#07c160';
          break;
        case '待审核':
          text = this.$t("newAdd.toAudit");
          color = '#ff976a';
          break;
        default:
          text = this.$t("newAdd.rests");
          color = '#ee0a24';
          break;
      }
      return {text,color}
    },
    getStatus2(status) {
      let text = ''
      switch (status) {
        case '审核中':
          text = this.$t("bank.underReview")
          break;
        case '已通过':
          text = this.$t("bank.passed")
          break;
        case '汇款中':
          text = this.$t("bank.remitting")
          break;
        case '已汇款':
          text = this.$t("bank.remitted")
          break;
        case '未通过':
          text = this.$t("bank.notApproved")
          break;
        default:
          break;
      }
      return text
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  min-height: 100vh;

  .content {
    padding: 0 calc(10rem / 16);

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #132235;
      border-radius: calc(5rem / 16);
      height: calc(40rem / 16);
      overflow: hidden;

      &_list {
        height: calc(40rem / 16);
        flex: 0 0 20%;
        color: var(--light);
        font-size: calc(13rem / 16);
        transition: all .5s linear;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(15rem / 16);
        text-align: center;
      }

      .active {
        background: linear-gradient(179deg, #13a2ba, #087c95);
      }
    }

    .list {
      .list_cont {
        background: var(--light);
        margin-top: calc(20rem / 16);
        padding: calc(10rem / 16);
        border-radius: calc(4rem / 16);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .list_left {
          display: flex;
          align-items: center;

          img {
            width: calc(30rem / 16);
            height: calc(22rem / 16);
            display: block;
          }

          .list_conts {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: calc(10rem / 16);

            .list_type {
              display: flex;
              align-items: center;
              color: var(--textColor);
              font-size: calc(18rem / 16);

              .list_status {
                color: var(--textColor);
                margin-left: calc(5rem / 16);
                font-size: calc(12rem / 16);
              }
            }

            .list_bank {
              padding-top: calc(8rem / 16);
              color: var(--textColor);
              font-size: calc(12rem / 16);
            }

            .list_time {
              padding: calc(8rem / 16) 0;
              color: var(--gray1);
              font-size: calc(12rem / 16);
            }
          }
        }

        .list_right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .list_money {
            color: var(--red);
            font-size: calc(18rem / 16);
          }

          .list_order {
            color: var(--textColor);
            padding-bottom: calc(8rem / 16);
            font-size: calc(10rem / 16);
          }

          .list_time,
          .list_reason {
            padding: calc(8rem / 16) 0;
            color: var(--gray1);
            font-size: calc(12rem / 16);
          }
        }
      }
    }
  }

  .btns {
    position: fixed;
    height: calc(50rem / 16);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: #132235;
    color: #5d636e;

    .btn {
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(16rem / 16);

      .img {
        width: calc(20rem / 16);
        height: calc(20rem / 16);
        display: block;
        object-fit: cover;
        margin-right: calc(10rem / 16);
      }
    }

    .active {
      background: linear-gradient(179deg, #13a2ba, #087c95);
      color: var(--light);
    }
  }
}
</style>